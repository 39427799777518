@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 900;
  font-style: normal;
  src: local('NeueHaasDisplayBlack'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayBlack.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 900;
  font-style: italic;
  src: local('NeueHaasDisplayBlackItalic'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayBlackItalic.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 700;
  font-style: normal;
  src: local('NeueHaasDisplayBold'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayBold.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 700;
  font-style: italic;
  src: local('NeueHaasDisplayBoldItalic'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayBoldItalic.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 300;
  font-style: normal;
  src: local('NeueHaasDisplayLight'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayLight.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 300;
  font-style: italic;
  src: local('NeueHaasDisplayLightItalic'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayLightItalic.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 500;
  font-style: normal;
  src: local('NeueHaasDisplayMedium'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayMediu.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 500;
  font-style: italic;
  src: local('NeueHaasDisplayMediumItalic'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayMediumItalic.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 400;
  font-style: normal;
  src: local('NeueHaasDisplayRoman'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayRoman.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 400;
  font-style: italic;
  src: local('NeueHaasDisplayRomanItalic'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayRomanItalic.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 200;
  font-style: normal;
  src: local('NeueHaasDisplayThin'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayThin.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 200;
  font-style: italic;
  src: local('NeueHaasDisplayThinItalic'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayThinItalic.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 100;
  font-style: normal;
  src: local('NeueHaasDisplayXThin'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayXThin.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 100;
  font-style: italic;
  src: local('NeueHaasDisplayXThinItalic'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayXThinItalic.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 50;
  font-style: normal;
  src: local('NeueHaasDisplayXXThin'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayXXThin.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 50;
  font-style: italic;
  src: local('NeueHaasDisplayXXThinItalic'),
    url(../assets/font/neue-haas-grotesk-display-pro-cdnfonts/NeueHaasDisplayXXThinItalic.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Changeling Neo';
  src: local('ChangelingNeoRegular'),
    url(../assets/font/ChangelingNeo/ChangelingNeoRegular.otf)
      format('opentype');
}

@font-face {
  font-family: 'Changeling Neo Bold';
  src: local('ChangelingNeoBold'),
    url(../assets/font/ChangelingNeo/ChangelingNeoBold.otf) format('opentype');
}

@font-face {
  font-family: 'Gilroy Medium';
  src: url(../assets/font/Gilroy-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url(../assets/font/Montserrat-Medium.ttf) format('opentype');
}

.ant-dropdown-menu {
  background: #000;
}

.ant-dropdown-arrow::before {
  background: black;
}

.ant-select-item-group {
  font-weight: 700;
}

.ant-select-item-option-content {
  text-align: center;
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
}

.ant-btn.ant-btn-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn > .ant-btn-loading-icon {
  display: flex;
  align-items: center;
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-table-filter-trigger.active {
  color: #9600b0;
}

@media (max-width: 1400px) {
  .walletconnect-modal__base {
    max-width: 390px !important;
  }
}

input:autofill:focus {
  background-color: #111115 !important;
}

input:autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:visited {
  -webkit-box-shadow: 0 0 0 30px #111115 inset;
  background-color: #111115;
  color: #fff;
  -webkit-text-fill-color: #fff;
}

.active {
  border-bottom: #000 1px solid;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 700;
}

:root {
  --biga-blue: #0a3885;
  --main-white: #fff;
  --text-white: #e8e8e8;
  --text-gray: #e5e5e5;
  --containers-color: #15151b;
  --stroke-color: #3e3e3e;
  --background-main: #070709;
  --accent-purple: #e961ff;
}

#root {
  overflow-x: hidden !important;
}

body {
  background-color: var(--background-main);
  color: #000;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  letter-spacing: 0;
}

.active {
  border-bottom: #000 1px solid;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 700;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(255, 255, 255, 0.03);
}

.ant-select-item {
  color: #fff;

  &:hover {
    background-color: rgba(255, 255, 255, 0.03);
  }
}

.ant-select-item-option-content {
  color: #fff;
}

.ant-select-dropdown {
  background: #15151b;
}
